"use client";

import { useSetCreateProjectState } from "@/components/dialogs/create-project";
import { useProjectById, useProjects } from "@/hooks/project";
import { CloudPermissions, usePermission } from "@/hooks/resource";
import { useCurrentOrganizationId, useCurrentProjectId } from "@/store/current-project-id/client";
import { useAuth } from "@/store/user/client";
import { cn } from "@llamacloud/shared/utils";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "@llamaindex/component/ui/dropdown-menu";
import { SidebarGroupLabel, SidebarMenu, SidebarMenuButton, SidebarMenuItem, useSidebar } from "@llamaindex/component/ui/sidebar";
import ToolTipper from "@llamaindex/component/ui/tooltipper";
import { ChevronsUpDown, Folders, Plus, Settings } from "lucide-react";
import { usePathname, useRouter } from "next/navigation";
import { useCallback } from "react";
export const ProjectSwitcher = () => {
  const {
    isMobile,
    open
  } = useSidebar();
  const currentOrganizationId = useCurrentOrganizationId();
  const currentProjectId = useCurrentProjectId();
  const {
    data: currentProject
  } = useProjectById({
    projectId: currentProjectId
  });
  const {
    data: projects
  } = useProjects({
    organizationId: currentOrganizationId
  });
  const hasPermission = usePermission(CloudPermissions.FULL_WRITE);
  const setDialogState = useSetCreateProjectState();
  const {
    push
  } = useRouter();
  const user = useAuth();
  const pathname = usePathname();
  const handleProjectChange = useCallback((newProjectId: string) => {
    const project = projects.find(p => p.id === newProjectId);
    if (pathname.includes(currentProjectId)) {
      push(pathname.replace(currentProjectId, newProjectId));
    } else if (project) {
      push(`/project/${newProjectId}`);
    }
  }, [projects, pathname, currentProjectId, push]);
  return <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-component="ProjectSwitcher" data-sentry-source-file="project-switcher.tsx">
      <SidebarGroupLabel className="h-4 group-data-[collapsible=icon]:hidden" data-sentry-element="SidebarGroupLabel" data-sentry-source-file="project-switcher.tsx">
        Projects
      </SidebarGroupLabel>
      <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="project-switcher.tsx">
        <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-source-file="project-switcher.tsx">
          <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="project-switcher.tsx">
            <SidebarMenuButton size={open ? "lg" : "sm"} tooltip="Switch project" data-sentry-element="SidebarMenuButton" data-sentry-source-file="project-switcher.tsx">
              <Folders className="hidden size-4 group-data-[collapsible=icon]:block" data-sentry-element="Folders" data-sentry-source-file="project-switcher.tsx" />
              <div className="flex flex-1 items-center gap-3 text-left text-sm leading-tight group-data-[collapsible=icon]:hidden">
                <div className={cn("flex size-6 shrink-0 items-center justify-center rounded-md")}>
                  <Folders className="size-4" data-sentry-element="Folders" data-sentry-source-file="project-switcher.tsx" />
                </div>
                <div>
                  <p className="truncate font-semibold">
                    {currentProject.name}
                  </p>
                </div>
              </div>
              <ChevronsUpDown className="ml-auto group-data-[collapsible=icon]:hidden" data-sentry-element="ChevronsUpDown" data-sentry-source-file="project-switcher.tsx" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg" align="start" side={isMobile ? "bottom" : "right"} sideOffset={4} data-sentry-element="DropdownMenuContent" data-sentry-source-file="project-switcher.tsx">
            <DropdownMenuLabel className="text-xs text-muted-foreground" data-sentry-element="DropdownMenuLabel" data-sentry-source-file="project-switcher.tsx">
              Projects
            </DropdownMenuLabel>
            <div className="max-h-[300px] overflow-y-auto">
              {projects.map((project, index) => <DropdownMenuItem key={project.id} onClick={() => {
              handleProjectChange(project.id);
            }} className="gap-2 p-2">
                  {project.name}
                  <DropdownMenuShortcut>⌘{index + 1}</DropdownMenuShortcut>
                </DropdownMenuItem>)}
            </div>
            <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="project-switcher.tsx" />
            <ToolTipper delayDuration={25} disabled={!!user?.allowed.index} content={"Contact sales to access organization features"} data-sentry-element="ToolTipper" data-sentry-source-file="project-switcher.tsx">
              <div>
                <DropdownMenuItem disabled={!hasPermission || !user?.allowed.index} onClick={() => setDialogState({
                open: true
              })} data-sentry-element="DropdownMenuItem" data-sentry-source-file="project-switcher.tsx">
                  <Plus className="size-4" data-sentry-element="Plus" data-sentry-source-file="project-switcher.tsx" />
                  <span className="ml-2">Add Project</span>
                </DropdownMenuItem>
              </div>
            </ToolTipper>
            <DropdownMenuItem onClick={() => {
            push(`/organization/${currentOrganizationId}/settings/organization`);
          }} data-sentry-element="DropdownMenuItem" data-sentry-source-file="project-switcher.tsx">
              <Settings className="size-4" data-sentry-element="Settings" data-sentry-source-file="project-switcher.tsx" />
              <span className="ml-2">Manage Projects</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>;
};