import { runtimeEnv } from "@llamacloud/shared/env";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

let authDomain =
  typeof window !== "undefined"
    ? window.location.host
    : process.env.NEXT_PUBLIC_FRONTEND_URL;
if (!authDomain && process.env.NEXT_PUBLIC_VERCEL_URL) {
  authDomain = process.env.NEXT_PUBLIC_VERCEL_URL;
}

if (authDomain && authDomain.startsWith("http")) {
  const url = new URL(authDomain);
  authDomain = url.host;
}

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: authDomain,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

export const firebaseApp =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : initializeApp(firebaseConfig);

export const googleAuth =
  runtimeEnv.NEXT_PUBLIC_AUTH_DISABLED ||
  runtimeEnv.NEXT_PUBLIC_BYOC_MODE_ENABLED
    ? undefined
    : getAuth(firebaseApp);
